import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../../config';
import { combineReducers } from 'redux';
import {
    CHANNEL_ADD_ERROR,
    CHANNEL_ADD_IN_PROGRESS,
    CHANNEL_ADD_SUCCESS,
    CHANNEL_CHANGE_DIALOG_HIDE,
    CHANNEL_CHANGE_DIALOG_SHOW,
    CHANNEL_DESCRIPTION_SET,
    CHANNEL_ID_SET,
    CHANNEL_INFO_IV_SORT_VALUE_SET,
    CHANNEL_INFO_IV_TAB_SET,
    CHANNEL_NAME_SET,
    CHANNEL_UPDATE_ERROR,
    CHANNEL_UPDATE_IN_PROGRESS,
    CHANNEL_UPDATE_SUCCESS,
    CHANNEL_USER_NAME_SET,
    CHANNEL_USER_NAME_STATUS_SET,
    CHANNEL_USERNAME_SETTINGS_SET,
    CHANNELS_SETTINGS_TAB_SET,
    CREATE_CHANNEL_DIALOG_HIDE,
    CREATE_CHANNEL_DIALOG_SHOW,
    FETCH_CHANNEL_AVAILABILITY_SUCCESS,
    FETCH_CHANNEL_INFO_ERROR,
    FETCH_CHANNEL_INFO_IN_PROGRESS,
    FETCH_CHANNEL_INFO_SUCCESS,
    FETCH_CHANNEL_IV_ERROR,
    FETCH_CHANNEL_IV_IN_PROGRESS,
    FETCH_CHANNEL_IV_SUCCESS,
    FETCH_CHANNEL_UPDATE_ERROR,
    FETCH_CHANNEL_UPDATE_IN_PROGRESS,
    FETCH_CHANNEL_UPDATE_SUCCESS,
    FETCH_CHANNELS_AVAILABILITY_ERROR,
    FETCH_CHANNELS_AVAILABILITY_IN_PROGRESS,
    FETCH_CHANNELS_ERROR,
    FETCH_CHANNELS_IN_PROGRESS,
    FETCH_CHANNELS_SUCCESS,
    SAVE_BUTTON_ENABLE_SET,
    SETTINGS_CHANNEL_ADDRESS_SET,
    SETTINGS_CHANNEL_DESCRIPTION_SET,
    SETTINGS_CHANNEL_IMAGE_SET,
    SETTINGS_CHANNEL_NAME_SET,
    SETTINGS_CHANNEL_ALLOW_PUBLISHERS_SET,
} from '../../constants/channels';
import { DISCONNECT_SET } from '../../constants/account';
import { EMPTY_VALUES_SET } from '../../constants/createAssets';
import _videoDrops from './videoDrops';
import _queryContracts from './queryContracts';
import _queryVideoDrops from './queryVideoDrops';

const channelsList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNELS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNELS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case FETCH_CHANNELS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const channelID = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case CHANNEL_ID_SET:
    case FETCH_CHANNELS_SUCCESS:
        return {
            value: action.value,
        };
    case EMPTY_VALUES_SET:
        return {
            value: [],
        };

    default:
        return state;
    }
};

const createChannelDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case CREATE_CHANNEL_DIALOG_SHOW:
        return {
            open: true,
        };
    case CREATE_CHANNEL_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const userName = (state = '', action) => {
    if (action.type === CHANNEL_USER_NAME_SET) {
        return action.value;
    } else if (action.type === CREATE_CHANNEL_DIALOG_HIDE) {
        return '';
    }

    return state;
};

const channelAdd = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case CHANNEL_ADD_IN_PROGRESS:
    case CHANNEL_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CHANNEL_ADD_SUCCESS:
    case CHANNEL_UPDATE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CHANNEL_ADD_ERROR:
    case CHANNEL_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const channelInfo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_INFO_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_INFO_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_CHANNEL_INFO_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const channelIV = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_IV_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_IV_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case FETCH_CHANNEL_IV_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const channelIVTab = (state = {
    value: 'all',
}, action) => {
    switch (action.type) {
    case CHANNEL_INFO_IV_TAB_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const channelIVSort = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CHANNEL_INFO_IV_SORT_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const updateChannel = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_CHANNEL_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_UPDATE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_CHANNEL_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const settingsChannelImage = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_IMAGE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const settingsChannelName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_NAME_SET:
        return {
            value: action.value,
        };
    case FETCH_CHANNEL_INFO_SUCCESS:
        return {
            value: action.value && action.value.name,
        };

    default:
        return state;
    }
};

const settingsChannelDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case FETCH_CHANNEL_INFO_SUCCESS:
        return {
            value: action.value && action.value.description,
            valid: true,
        };

    default:
        return state;
    }
};

const settingsChannelAddress = (state = {
    value: [],
    switch: false,
}, action) => {
    switch (action.type) {
    case SETTINGS_CHANNEL_ADDRESS_SET:
        return {
            ...state,
            value: action.value,
        };
    case SETTINGS_CHANNEL_ALLOW_PUBLISHERS_SET:
        return {
            ...state,
            switch: action.value,
        };
    case FETCH_CHANNEL_INFO_SUCCESS:
        return {

            ...state,
            value: action.value && action.value.publishers,
            switch: !!(action.value && action.value.publishers && action.value.publishers.length),
        };

    default:
        return state;
    }
};

const channelChangeDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CHANNEL_CHANGE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CHANNEL_CHANGE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const channelAvailability = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNELS_AVAILABILITY_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNEL_AVAILABILITY_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_CHANNELS_AVAILABILITY_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const usernameStatus = (state = true, action) => {
    if (action.type === CHANNEL_USER_NAME_STATUS_SET) {
        return action.value;
    } else if (action.type === CREATE_CHANNEL_DIALOG_HIDE) {
        return true;
    }

    return state;
};

const channelSettingsTab = (state = 'general', action) => {
    if (action.type === CHANNELS_SETTINGS_TAB_SET) {
        return action.value;
    }

    return state;
};

const channelName = (state = '', action) => {
    if (action.type === CHANNEL_NAME_SET) {
        return action.value;
    } else if (action.type === FETCH_CHANNEL_INFO_SUCCESS) {
        return action.value && action.value.name;
    }

    return state;
};

const channelUserNameSettings = (state = '', action) => {
    if (action.type === CHANNEL_USERNAME_SETTINGS_SET) {
        return action.value;
    } else if (action.type === FETCH_CHANNEL_INFO_SUCCESS) {
        return action.value && (action.value.username || action.value.custom_id);
    }

    return state;
};

const channelDescription = (state = '', action) => {
    if (action.type === CHANNEL_DESCRIPTION_SET) {
        return action.value;
    } else if (action.type === FETCH_CHANNEL_INFO_SUCCESS) {
        return action.value && action.value.description;
    }

    return state;
};

const saveButtonEnable = (state = false, action) => {
    switch (action.type) {
    case SAVE_BUTTON_ENABLE_SET:
        return action.value;
    default:
        return state;
    }
};

export default combineReducers({
    channelsList,
    channelID,
    createChannelDialog,
    channelInfo,

    channelIV,
    channelIVTab,
    channelIVSort,

    updateChannel,
    settingsChannelImage,
    settingsChannelName,
    settingsChannelDescription,
    settingsChannelAddress,

    userName,
    channelAdd,
    channelChangeDialog,
    channelAvailability,
    usernameStatus,

    channelSettingsTab,
    channelName,
    channelUserNameSettings,
    channelDescription,
    videoDrops: _videoDrops,
    queryContracts: _queryContracts,
    queryVideoDrops: _queryVideoDrops,

    saveButtonEnable,
});
