import flixIcon from './assets/tokens/flix.svg';

export const config = {
    API_URL: 'https://dev-api.omniflix.studio',
    RPC_URL: 'https://rpc.devnet-alpha.omniflix.network',
    REST_URL: 'https://api.devnet-alpha.omniflix.network',
    DATA_LAYER: 'https://data-layer-dev.omniflix.studio',
    FAUCET_URL: 'https://faucet-devnet-alpha.omniflix.network',
    CHAIN_ID: 'devnet-alpha-3',
    CHAIN_NAME: 'OmniFlix Hub Devnet',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
};

export const contractAddresses = {
    MULTI_MINT_VIDEO_DROP: 'omniflix1vy8879cnglw8q8mmjkcxqetw6p07ejtxm80lyas6sj3mtyn6wedsrw0cen',
};

export const ACTIVITY_URL = 'https://activity-api-dev-alpha.omniflix.studio';
export const EXPLORER_URL = 'https://www.mintscan.io/omniflix';

export const DEFAULT_TOTAL = 20;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_SEARCH = null;
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
export const TRANSACTION_SET_TIME_OUT = 3000;
export const COLLECTION_PREFIX = 'onftdenom';
export const DEFAULT_GAS_FEE = '500000';

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const OMNIFLIX_NUCLEUS_URL = 'https://dev.omniflix.market';
export const RUNNER_URL = 'https://dev-runner.omniflix.studio';
export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const IPFS_REFERENCE_PATH = 'ipfs://';
export const INSYNC_URL = 'https://dev.omniflix.co';
export const STREAM_PAY_URL = 'https://sp.omniflix.network';
export const MINT_ACCESS = 'https://forms.gle/Aj3oc2fbcoCTX9KF7';
export const GAS_PRICE_STEP_LOW = 0.001;
export const GAS_PRICE_STEP_AVERAGE = 0.0025;
export const GAS_PRICE_STEP_HIGH = 0.025;

export const DEFAULT_UPLOAD_LIMIT = 2 * 1024 * 1024 * 1024;

export const IPFS_GATEWAYS = [
    'https://ipfs-gateway-2.omniflix.studio/ipfs',
    'https://ipfs.omniflix.studio/ipfs',
    'https://ipfs-gateway.omniflix.studio/ipfs',
    'https://ipfs.io/ipfs',
];

export const gas = {
    LIST_NFT: 200000,
    MINT_NFT: 300000,
    ITC_CLAIM: 200000,
};

export const socialLinks = {
    TWITTER: 'https://twitter.com/OmniFlixNetwork',
    TELEGRAM: 'https://t.me/OmniFlixChat',
    DISCORD: 'https://discord.com/invite/6gdQ4yZSTC',
    GITHUB: 'https://github.com/OmniFlix',
    WEBSITE: 'https://omniflix.network/',
    YOUTUBE: 'https://www.youtube.com/OmniFlixNetwork',
};

export const appsLinks = {
    NUCLEUS: OMNIFLIX_NUCLEUS_URL,
    IN_SYNC: INSYNC_URL,
    TV: 'https://dev.omniflix.tv',
};

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
            gasPriceStep: {
                low: GAS_PRICE_STEP_LOW,
                average: GAS_PRICE_STEP_AVERAGE,
                high: GAS_PRICE_STEP_HIGH,
            },
        },
    ],
    coinType: 118,
    features: ['ibc-transfer'],
};

export const FaucetList = [{
    name: config.COIN_DENOM,
    chain_id: config.CHAIN_ID,
    chain: 'omniflix',
    icon: flixIcon,
    denom: config.COIN_MINIMAL_DENOM,
}];

export const cosmoStationChainConfig = {
    chainId: chainId,
    chainName: chainName,
    addressPrefix: prefix,
    baseDenom: coinMinimalDenom,
    displayDenom: coinDenom,
    restURL: config.REST_URL,
    coinType: '118', // optional (default: '118')
    decimals: coinDecimals, // optional (default: 6)
    gasRate: {
        average: '0.0025',
        low: '0.001',
        tiny: '0.025',
    },
};
